<template>
  <v-row
    align="center"
    justify="center">
    <v-col
      cols="12"
      sm="8">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <span> {{ $t("crudActions.indexModel", { model: $t("models.zones.multiple") }) }} </span>
          <v-btn
            small
            color="primary"
            :to="{ name: $routes.zone.createRoute.name }">
            {{ $t("crudActions.createModel", { model: $t("models.zones.single") }) }}
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :loading="loading"
            :headers="tableColumns"
            mobile-breakpoint="400"
            :items="zones">
            <template #item.action="{ item }">
              <v-btn
                icon
                @click="editZone(item)">
                <font-awesome-icon icon="pencil-alt" />
              </v-btn>
              <v-btn
                v-if="item.readOnly === false"
                icon
                @click="destroyZone(item)">
                <font-awesome-icon icon="trash" />
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { fetchZones, deleteZone } from "../../api/zone.js";
import ContentLoader from "../../mixins/ContentLoader.js";
import { i18n } from "../../i18n";

export default {
  name: "ZoneIndex",
  mixins: [ContentLoader],
  data() {
    return {
      zones: [],
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          value: "groupZoneNumber",
          text: "#",
        },
        {
          value: "name",
          text: i18n.t("attributes.zones.name"),
        },
        {
          value: "action",
          text: i18n.t("actions.text"),
        },
      ];
    },
  },
  async mounted() {
    this.$startLoading();

    try {
      const {
        data: { zones },
      } = await fetchZones();

      this.zones = zones;

      await this.$nextTick();
    } finally {
      this.$finishedLoading();
    }
  },
  methods: {
    editZone({ id }) {
      this.$router.push({
        name: this.$routes.zone.editRoute.name,
        params: { id },
      });
    },
    destroyZone({ id }) {
      deleteZone(id).
        then(() => {
          this.zones = this.zones.filter((z) => z.id !== id);
        }).
        catch((error) => console.error(error));
    }
  },
};
</script>
