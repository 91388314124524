import httpClient from "./httpClient";
import ZoneUpdateCategory from "@/imported/ZoneUpdateCategory.json";
import ZoneIndexCategory from "@/imported/ZoneIndexCategory.json";


const resource = "zones";

export function createZone(zone) {
  return httpClient.post(`/${resource}`, { zone });
}

export function fetchZones() {
  const params = { category: ZoneIndexCategory.general };
  return httpClient.get(`/${resource}`, { params });
}

export function fetchZone(id) {
  return httpClient.get(`/${resource}/${id}`);
}

export function fetchUserAndPoolZones() {
  const params = { category: ZoneIndexCategory.manage };
  return httpClient.get(`/${resource}`, { params });
}

export function generalUpdateZone(id, zone) {
  const category = ZoneUpdateCategory.general;

  return httpClient.put(`/${resource}/${id}`, { zone, category });
}

export function manageUpdateZone(zones) {
  const category = ZoneUpdateCategory.manage;

  return httpClient.put(`/${resource}/`, { zones, category });
}

export function getParams() {
  return httpClient.get(`/${resource}/params`);
}

export function deleteZone(id) {
  return httpClient.delete(`/${resource}/${id}`)
}
